import { useCallback, useEffect, useState, useMemo } from "react";
import {
  VStack,
  Button,
  Text,
  HStack,
  Select,
  Input,
  Box
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { networkParams } from "./networks";
import { toHex, truncateAddress } from "./utils";
import { BigNumber, ethers, utils } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "./providerOptions";
import Web3 from "web3";
import { abi } from "./consts";

import WebComponent from "./web/Web"
import MobileComponent from "./mobile/mobile"


const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions // required
});

export default function Home() {


  return (
    <WebComponent />
  );
}
